import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  data: () => ({
    tableArea: null,

    rawData: [],
    overallData: null,

    chartInfo: null,

    scale: {
      oa: null,
      dt: null,
    },

    twColWidth: null,
    cumColWidth: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.chartInfo = {
        viewTitle: sql1.VIEW_TITLE,
        viewOverall: sql1.VIEW_OVERALL,
        viewDetail: sql1.VIEW_DETAIL,

        width: sql1.WIDTH,

        title: {
          text: sql1.TITLE_TEXT,
          tSize: sql1.TITLE_TSIZE,
          tColor: sql1.TITLE_TCOLOR,
          tAnchor: sql1.TITLE_ANCHOR,
        },

        overall: {
          currentTSize: sql1.OA_CURRENT_TSIZE,
          currentTColor: sql1.OA_CURRENT_TCOLOR,
          tSize: sql1.OA_TSIZE,
          tColor: sql1.OA_TCOLOR,

          bar: {
            bgColor: sql1.OA_BAR_BGCOLOR,
            bgOpacity: sql1.OA_BAR_BGOPACITY,
            color: sql1.OA_BAR_COLOR,
            opacity: sql1.OA_BAR_OPACITY,
            tSize: sql1.OA_BAR_TSIZE,
            tColor: sql1.OA_BAR_TCOLOR,
            height: sql1.OA_BAR_HEIGHT,
          },

          weekVisible: sql1.OA_WEEK_VISIBLE,
        },

        detail: {
          rowHeight: sql1.DT_ROW_HEIGHT,
          groupWidth: sql1.DT_G_WIDTH,
          groupName: sql1.DT_G_NAME,
          groupCol: sql1.DT_G_COLUMN,
          barWidth: sql1.DT_BAR_WIDTH,

          bar: {
            bgColor: sql1.DT_BAR_BGCOLOR,
            bgOpacity: sql1.DT_BAR_BGOPACITY,
            color: sql1.DT_BAR_COLOR,
            opacity: sql1.DT_BAR_OPACITY,
            tSize: sql1.DT_BAR_TSIZE,
            tColor: sql1.DT_BAR_TCOLOR,
          },
        },
      }

      this.overallData = this.rawData.filter(f => f[this.chartInfo.detail.groupCol] == 'Overall')[0]

      this.set_ProgressScale()

      Defs.setDefs_Gradients(this.svg, 0, 'LinearA4', this.chartInfo.overall.bar.color, [0.5, 1.5, 0.5, -0.3], this.localId)
    },

    set_ProgressScale() {
      this.scale.oa = d3.scaleLinear()
      .domain([0, 100])
      .range([0, this.chartInfo.width])

      this.scale.dt = d3.scaleLinear()
      .domain([0, 100])
      .range([0, this.chartInfo.detail.barWidth])
    },
  }
}