/* 
  ### Customized Chart Components ### 
  Predefined component's behaviors for the Dashboard
*/

// Summaries
import J2_Cube_B                 from '../09_Components/Summaries/Cube_B/index.vue'
import J2_Cube_C                 from '../09_Components/Summaries/Cube_C/index.vue'
import J2_Cube_BIND              from '../09_Components/Summaries/Cube_BIND/index.vue'
import J2_Cube_A                 from '../09_Components/Summaries/Cube_A/index.vue'
import J2_Sentence_A             from '../09_Components/Summaries/Sentence_A/index.vue'
import J2_Week_Performance       from '../09_Components/Summaries/Week_Performance/index.vue'
import J2_Week_Performance_UNIT  from '../09_Components/Summaries/Week_Performance_UNIT/index.vue'

// PREMAVERA
import J2_P6_SCURVE_MINI        from '../09_Components/Premavera/SCurve_Mini/index.vue'
import J2_P6_DONUTS             from '../09_Components/Premavera/Donuts/index.vue'
import J2_P6_DONUTS_R1          from '../09_Components/Premavera/Donuts_R1/index.vue'
import J2_P6_TABLE_EL           from '../09_Components/Premavera/Table_EL/index.vue'
import J2_P6_TREND_EL           from '../09_Components/Premavera/Trend_EL/index.vue'
import J2_P6_TUBE_EL            from '../09_Components/Premavera/Tube_EL/index.vue'

// Data Tables
import J2_DataTable_A            from '../09_Components/DataTables/DataTable_A/index.vue'
import J2_DataTable_B            from '../09_Components/DataTables/DataTable_B/index.vue'
import J2_DataTable_C            from '../09_Components/DataTables/DataTable_C/index.vue'

// Table Charts
import J2_Punch                  from '../09_Components/TableCharts/Punch/index.vue'
import J2_Progress               from '../09_Components/TableCharts/Progress/index.vue'
 
// Charts
import J2_Tube_A                 from '../09_Components/CustomCharts/Tube/Normal/index.vue'
import J2_Tube_UNIT              from '../09_Components/CustomCharts/Tube_UNIT/Normal/index.vue'
import J2_Gauge_Normal           from '../09_Components/CustomCharts/Gauge/Normal/index.vue'
import J2_Skyline_Month_A        from '../09_Components/CustomCharts/Skyline/Month_A/index.vue'

// Pyramid Chart
import J2_Pyramid                from '../09_Components/Safety/Pyramid_R1/index.vue'

// Viewer
import J2_Filter_Viewer          from '../09_Components/Graphics/Filter_Viewer/index.vue'
import J2_SVG_Viewer             from '../09_Components/Graphics/SVG_Viewer/index.vue'
import J2_SVG_Viewer_v2          from '../09_Components/Graphics/SVG_Viewer_v2/index.vue'

// Summary table depth2 for Crisp
import J2_Summary_Table          from '../09_Components/Developing/Summary_Table_Dep2/index.vue'

export default {
  // Summaries
    J2_Cube_A,
    J2_Cube_B,
    J2_Cube_C,
    J2_Cube_BIND,
    J2_Sentence_A,
    J2_Week_Performance,
    J2_Week_Performance_UNIT,

  // PREMAVERA
    J2_P6_SCURVE_MINI,
    J2_P6_DONUTS,
    J2_P6_DONUTS_R1,
    J2_P6_TABLE_EL,
    J2_P6_TREND_EL,
    J2_P6_TUBE_EL,

  // Data Tables
    J2_DataTable_A,
    J2_DataTable_B,
    J2_DataTable_C,

  // Table Charts
    J2_Punch,
    J2_Progress,

  // Charts
    J2_Tube_A,
    J2_Tube_UNIT,
    J2_Gauge_Normal,
    J2_Skyline_Month_A,

  // Pyramid Chart
    J2_Pyramid,

  // Viewer
    J2_Filter_Viewer,
    J2_SVG_Viewer,
    J2_SVG_Viewer_v2,

  // Summary table depth2 for Crisp
    J2_Summary_Table,

}
