import CONST from '../../../../../includes/primitives/_constant_trion'
import icon from '../../../../../includes/primitives/icons'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData: [],
    common: null,
    header: null,
    table: null,
    columns: null,
    filters: null,

    titleGroup: [],
    icon: icon.arrow,
    tableWidth: 0,
    barScale: null,

    canvasArea: null,

    Common: {
      area: {
        x: 0,
        y: 0,
        font: 'roboto',
      },
    },
  
    CTypes: {
      header: {
        fColor: '#f3f3f2',
        fOpacity: 0.8,
        margin: 2,
        line: {
          visible: 'Y',
          color: '#999999',
          opacity: 0.5,
          width: 0.5,
        },
        devide: 'Y',
        depth: [
          {
            height: 17,
            tSize: 11,
            tColor: '#333',
          },
          {
            height: 14,
            tSize: 10,
            tColor: '#c7c3c1',
          }
        ],
        group: [
          {
            title: 'Overdue',
            tColor: '#f370ac',
          },
        ],
      },
      cells: {
        tSize: 10,
        height: 18,
        cellSpace: 4,
        total: 'Y',
        line: {
          visible: 'Y',
          color: '#999999',
          opacity: 0.5,
          width: 0.5,
        },
        bar: {
          height: 11,
          color: '#f370ac',
          opacity: 0.6,
        },
      },
    },
  
    // DataCols: {
    //   Columns: [
    //     {title: 'Area', ref: 'AREA', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'N', zero: 'Y', lastOne: 'N', filter: ''},
    //     {title: 'Disc.', ref: 'DISC', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'Y', zero: 'Y', lastOne: 'N', filter: ''},
    //     {title: 'Total', ref: 'TOTAL', group: '', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Start', ref: 'PS_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Issued', ref: 'PF_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Start', ref: 'AS_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Issued', ref: 'AF_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Start', ref: 'OS', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', filter: ''},
    //     {title: 'Issued', ref: 'OF', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', filter: ''},
    //     {title: 'Plan', ref: 'PLAN', group: 'Progress', width: 40, type: 'LINK', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Actual', ref: 'ACT', group: 'Progress', width: 80, type: 'BAR', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //     {title: 'Delta', ref: 'DEV', group: 'Progress', width: 40, type: 'DELTA', format: '', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', filter: ''},
    //   ],
    //   Filters: [
    //     {ref: 'AREA', jMap: 'KEY1'},
    //   ],
    // },

    localData: {
      //
    },
  }),
}