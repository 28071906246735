import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {
    set_Value(format, value) {
      if (!value) return value

      if(format == '$') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if(format == '%') {
        return `${value}%`
      } else {
        return value
      }
    },

    set_ScaleValue(domain, length, value) {
      let scaleValue = d3.scaleLinear()
      .domain(domain)
      .range(length)

      return scaleValue(value)
    },
  }
}