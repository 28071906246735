 import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {

    init_gmx_SkylineValues_Month(dataSet_, d_) {
      
      if(dataSet_.length === 0) {
        console.log('[USER: setTimelineEnv()] Timeline data is not available in the "DateItems".')
        return
      }

      // Timeline의 시작 날짜와 종료날짜를 구한다.
      let sDate_ = moment(moment(d_.startDate).startOf('month').format()).toDate()
      let eDate_ = moment(moment(d_.endDate).endOf('month').format('YYYY-MM-DD 23:59:59')).toDate()

      // console.log('startDate: ', sDate_)
      // ### Set Timeline-Values for the month ---------------------------------------------------
      let nextMonth = sDate_
      // let nextMonth = moment(moment(sDate_).add(1,'months').format()).toDate()
      // console.log('nextMonth: ', nextMonth)

      while(nextMonth <= eDate_) {
        let value_ = {
          name: d3.timeFormat('%b')(nextMonth), 
          sDate: nextMonth,
          mDate: null,
          eDate: moment(moment(nextMonth).endOf('month').format('YYYY-MM-DD 23:59:59')).toDate()
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.month.push({ name: '', ...value_ })
        nextMonth = moment(nextMonth).add(1, 'months').toDate()
      }
      // console.log('this.timeline.month: ', this.timeline.month)
      

      // ### Set Timeline-Values for the Year ---------------------------------------------------
      eDate_ = null
      let years_ = []
      let years__ = []

      this.timeline.month.forEach((m_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(m_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(m_.eDate)
          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(m_)
        if(i === this.timeline.month.length - 1) years_.push(years__)
      })

      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // console.log('this.timeline.year: ', this.timeline.year)
      
   
      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.month[0].sDate, 
        this.timeline.month[this.timeline.month.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])

    },

    
    get_gmx_TimelineCDate_Month(date) {
      
      let date_ = new Date(date)
      let cdate = this.timeline.month.find(f => date_ >= f.sDate && date_ <= f.eDate)

      return cdate ? cdate : null
    },





















  }

}