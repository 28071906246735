import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {

    draw_Forms() {
      let header = this.header
      let cells = this.cells
      let col = this.columns
      let dep = header.depth

      this.canvasArea = this.svg
      .append('g')
      .attr('id', `canvas_${this.localId}`)
      .attr('transform', `translate(${this.common.area.x + 0.5},${this.common.area.y + 0.5})`)
      .attr('font-family', this.common.area.font)

      let table = this.canvasArea
      .append('g')
      .attr('id', `table_${this.localId}`)

      if (header.line.visible == 'Y') {
        table
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.tableWidth)
        .attr('y2', 0)
        .attr('stroke', header.line.color)
        .attr('stroke-width', header.line.width)
        .attr('opacity', header.line.opacity)

        table
        .append('line')
        .attr('x1', 0)
        .attr('y1', dep[0].height + dep[1].height)
        .attr('x2', this.tableWidth)
        .attr('y2', dep[0].height + dep[1].height)
        .attr('stroke', header.line.color)
        .attr('stroke-width', header.line.width)
        .attr('opacity', header.line.opacity)
      }

      col.forEach((d,i) => {
        // Header
        table
        .append('rect')
        .attr('x', d.sX)
        .attr('y', header.margin)
        .attr('width', d.width)
        .attr('height', dep[0].height + dep[1].height - header.margin * 2)
        .attr('fill', header.fColor)
        .attr('opacity', header.fOpacity)

        if (header.line.visible == 'Y' && header.devide == 'Y' && !(i == col.length - 1)) {
          table
          .append('line')
          .attr('class', `vertical_line`)
          .attr('x1', d.fX)
          .attr('y1', d.sY)
          .attr('x2', d.fX)
          .attr('y2', d.fY)
          .attr('stroke', header.line.color)
          .attr('stroke-width', header.line.width)
          .attr('opacity', header.line.opacity)
        }

        if (d.group == '') {
          table
          .append('text')
          .attr('x', d.mX)
          .attr('y', ((dep[0].height + dep[1].height) / 2).toFixed(0))
          .attr('font-size', dep[0].tSize)
          .attr('fill', dep[0].tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text(d.title)
        } else {
          table
          .append('text')
          .attr('x', d.mX)
          .attr('y', (dep[0].height + dep[1].height / 2).toFixed(0) - 1)
          .attr('font-size', dep[1].tSize)
          .attr('fill', dep[1].tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text(d.title)
        }

        // Cells
        this.rawData.forEach((r,j) => {
          let sY = dep[0].height + dep[1].height
          
          if (d.type == 'DELTA') {
            let arrow = this.setDeltaColor(r[d.ref], 'icon')

            table
            .append('text')
            .attr('id', `delta_text_${j}_${this.localId}`)
            .attr('x', d.x)
            .attr('y', sY + cells.height * (0.5 + j))
            .attr('font-size', cells.tSize)
            .attr('fill', this.setDeltaColor(r[d.ref]))
            .attr('text-anchor', d.align)
            .attr('alignment-baseline', 'central')
            .text(() => {
              let text_ = r[d.ref]
              
              if (cells.total == 'Y' && j == this.rawData.length - 1) {
                if (i == 0) {text_ = 'Total'}
                else if (d.lastOne == 'N') {text_ = ''}
              }

              return this.set_Value(d.format,text_)
            })

            if (!(cells.total == 'Y' && j == this.rawData.length - 1 && d.lastOne == 'N')) {
              table
              .append('path')
              .attr('transform', `translate(${d.fX - cells.tSize * 0.8}, ${sY + cells.height * (j + 0.25)}) scale(${cells.tSize * 0.06})`)
              .attr('d', arrow)
              .attr('fill', this.setDeltaColor(r[d.ref]))
            }

            if (d.align == 'end') {
              d3.select(`#delta_text_${j}_${this.localId}`).attr('transform', `translate(${-cells.tSize * 0.8}, 0)`)
            }
          } else if (d.type == 'BAR') {
            let barHeight = cells.bar.height
            let space = r[d.ref] <= 55 ? 3 : -3

            if (!(cells.total == 'Y' && j == this.rawData.length - 1 && d.lastOne == 'N')) {
              // Background
              table
              .append('rect')
              .attr('x', d.x)
              .attr('y', sY + (cells.height - cells.bar.height) / 2 + cells.height * j)
              .attr('width', d.width - cells.cellSpace * 2)
              .attr('height', barHeight)
              .attr('fill', '#eee')
              .attr('opacity', 0.6)

              // Actual
              table
              .append('rect')
              .attr('x', d.x)
              .attr('y', sY + (cells.height - cells.bar.height) / 2 + cells.height * j)
              .attr('width', this.set_ScaleValue([0, 100], [0, d.width - cells.cellSpace * 2], r[d.ref]))
              .attr('height', barHeight)
              .attr('fill', cells.bar.color)
              .attr('opacity', cells.bar.opacity)
            }

            table
            .append('text')
            .attr('x', d.x + this.set_ScaleValue([0, 100], [0, d.width - cells.cellSpace * 2], r[d.ref]) + space)
            .attr('y', sY + cells.height * (0.5 + j))
            .attr('font-size', cells.tSize)
            .attr('fill', d.tColor)
            .attr('text-anchor', r[d.ref] <= 55 ? 'start' : 'end')
            .attr('alignment-baseline', 'central')
            .text(() => {
              let text_ = r[d.ref]

              if (text_ == 0 && d.zero == 'N') {text_ = ''}
              else if (cells.total == 'Y' && j == this.rawData.length - 1) {
                if (i == 0) {text_ = 'Total'}
                else if (d.lastOne == 'N') {text_ = ''}
              }

              return this.set_Value(d.format,text_)
            })
          } else {
            table
            .append('text')
            .attr('id', `text_${j}`)
            .attr('x', d.x)
            .attr('y', sY + cells.height * (0.5 + j))
            .attr('font-size', cells.tSize)
            .attr('fill', d.tColor)
            .attr('text-anchor', d.align)
            .attr('alignment-baseline', 'central')
            .text(() => {
              let text_ = r[d.ref]

              if (text_ == 0 && d.zero == 'N') {
                text_ = ''
              } else if (cells.total == 'Y' && j == this.rawData.length - 1) {
                if (i == 0) {text_ = 'Total'}
                else if (d.lastOne == 'N') {text_ = ''}
              }

              if (j > 0 && d.overlap == 'N' && r[d.ref] == this.rawData[j-1][d.ref]) {
                text_ = ''
              }

              return this.set_Value(d.format,text_)
            })
            .call(this.call_Link, {
              type: d.type,
              request: d.request,
              filter: d.filter,
              data: r,
            })
          }

          if (cells.line.visible == 'Y' && j != 0) {
            if (!(j > 0 && d.overlap == 'N' && r[d.ref] == this.rawData[j-1][d.ref])) {
              table
              .append('line')
              .attr('x1', d.sX)
              .attr('y1', (sY + cells.height * (j)).toFixed(0))
              .attr('x2', d.fX)
              .attr('y2', (sY + cells.height * (j)).toFixed(0))
              .attr('stroke', cells.line.color)
              .attr('stroke-width', cells.line.width)
              .attr('opacity', () => {
                if (cells.total == 'Y' && (j == this.rawData.length - 1)) {
                  return 1
                } else {
                  return cells.line.opacity
                }
              })
            }

            if (j == this.rawData.length - 1) {
              table
              .append('line')
              .attr('x1', d.sX)
              .attr('y1', (sY + cells.height * (j + 1)).toFixed(0))
              .attr('x2', d.fX)
              .attr('y2', (sY + cells.height * (j + 1)).toFixed(0))
              .attr('stroke', cells.line.color)
              .attr('stroke-width', cells.line.width)
              .attr('opacity', () => {
                if (cells.total == 'Y' && (j == this.rawData.length - 1)) {
                  return 1
                } else {
                  return cells.line.opacity
                }
              })
            }
          }
        })
      })

      // Title - Group
      this.titleGroup.forEach((d,i) => {
        let textColor = ''
        
        if (header.group.find(f => f.title == d.title)) {
          textColor = header.group.find(f => f.title == d.title).tColor
        }

        table
        .append('text')
        .attr('x', d.x)
        .attr('y', d.y + 1)
        .attr('font-size', dep[0].tSize)
        .attr('fill', () => {
          if (textColor != '') {return textColor}
          else {return dep[0].tColor}
        })
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .text(d.title)

        // if (header.line.visible == 'Y' && header.devide == 'Y' && !(i == this.titleGroup.length - 1)) {
        //   table
        //   .append('line')
        //   .attr('class', `vertical_line`)
        //   .attr('x1', d.fX).attr('y1', d.sY)
        //   .attr('x2', d.fX).attr('y2', d.fY)
        //   .attr('stroke', header.line.color)
        //   .attr('stroke-width', header.line.width)
        //   .attr('opacity', header.line.opacity)
        // }
      })
    },
    
  }
}